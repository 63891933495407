<template>
  <div id="app">
      <swiper ref="mySwiper" :options="swiperOptions" class="mySwiper">
        <swiper-slide v-for="(item,index) in picSwiper" :key="index">
          <img :src="item.src" class="swiperPic" alt="">
        </swiper-slide>
        <!-- 分页器 -->
        <div class="swiper-pagination" slot="pagination"></div>
        <!-- 前进后退按钮 -->
        <!-- <div class="swiper-button-prev" slot="button-prev"></div> -->
        <!-- <div class="swiper-button-next" slot="button-next"></div> -->
      </swiper>
      <div class="introduce">
        <div class="introLeft">
          <div class="inTitle">
            <div class="main">广州钧浩文化旅游发展有限公司</div>
            <div class="second">亚洲超级室内游乐园</div>
          </div>
          <ul class="desc">
            <li v-for="(item,index) in descText" :key="index">{{item}}</li>
          </ul>
        </div>
        <div class="introRight">
          <img v-for="(pic,index) in introPic" :key="index" :src="pic" alt="">
        </div>
      </div>
      <img src="@/assets/pic1.png" class="normalPic" alt="">  
      <img src="@/assets/pic2.png" class="normalPic" alt="">  
      <img src="@/assets/pic3.png" class="normalPic" alt="">  
      <div class="bottomFont">
        广州钧浩文化旅游发展有限公司&nbsp;&nbsp;
        <a href="https://beian.miit.gov.cn" class="bottomLink">粤ICP备2023060940号</a>
      </div>
    
    <!-- <a href="https://beian.miit.gov.cn" class="bottomFont">粤ICP备2023060940号-1</a> -->
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return{
      swiperOptions:{
        loop:true,
        clickable:true,
        autoplay:true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          color:"#E0610F",
        },
      },
      picSwiper: [
        {src:require('@/assets/2.png')}, 
        {src:require('@/assets/12_50.jpg')},
        {src:require('@/assets/owl1.png')},
        {src:require('@/assets/owl2.png')},
      ],
      descText:
      [
        "4大主题酒店",
        "4大IP主题乐园",
        "4大情景街区商业",
        "40+国际顶尖游乐设施",
        "600㎡室内恒温泳池,大型酒吧及KTV配套",
        "70+餐饮零食门店",
        "20+网红打卡情景装置,节假日演出及娱乐",
      ],
      introPic:
      [
        require('@/assets/intro_1.jpg'),
        require('@/assets/intro_2.jpg'),
        require('@/assets/intro_3.jpg'),
        require('@/assets/intro_4.jpg'),
      ],
    }
  },
  created(){
    // console.log(this.swiperPic);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*{
  margin:0;
  padding:0;
}
.header{
  position: fixed;
  top:0;
  left:0;
  background:rgba(255, 255, 255,0.6);
  width:100%;
  height:60px;
  z-index: 10;
}
.header>img{
  height:80%;
  position: absolute;
  top:0;
  bottom:0;
  left:20px;
  margin:auto;
}
.pic{
  width:100%;
}
.swiper{
  width:100%;
  /* height:50px; */
}
.swiperPic{
  width:100%;
}
.mySwiper .swiper-pagination-bullet-active{
  opacity: 1;
  background:#E0610F;
}
.introduce{
  width:100%;
  height:900px;
  padding:100px 80px;
  background:url("~@/assets/introduce.png") no-repeat 0 0/100%;
  box-sizing: border-box;
  display:flex;
  justify-content: space-between;
}
.introLeft,.introRight{
  width:50%;
}
.introRight{
  display:flex;
  justify-content: space-around;
  flex-wrap: wrap;
  transform:translateX(10px);
}
.introRight img{
  height:30%;
}
.inTitle{
  width: 800px;
  font-size: 35px;
  color:#E0610F;
  font-weight:bold;
  letter-spacing: 10px;
}
.inTitle>div{
  margin-bottom:20px;
  text-align: left;
}
.inTitle .second{
  Text-indent:3em;
}
.desc{
  list-style: none;
  width: 700px;
  margin-top: 40px;
  text-align: left;
  font-size: 25px;
  color:#E0610F;
  font-weight:bold;
  letter-spacing: 10px;
  margin-left:100px;
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.desc li{
  margin-top:50px;
}
.normalPic{
  width:100%;
}
.bottomFont{
  padding-bottom:10px;
}
.bottomLink{
  font-size:16px;
  margin:5px 0;
  
}
</style>
